<template>
  <div>
    <v-app-bar class="mt-2" color="rgba(0, 0, 0, 0)" flat dense>
      <v-toolbar-title>
        <span class="text-h5"> 営業マージン成績表</span>
        <span class="text-h6 ml-8">{{ dateStart | libertyTerm }} 期</span>
        <span class="text-subtitle-1 ml-2">{{ dateStart | date }} - {{ dateEnd | date }}</span>

        <span class="text-h6 ml-8">{{ $store.getters.user.name }}</span>
      </v-toolbar-title>

      <v-spacer />
      <v-sheet width="200">
        <v-select v-model="term" label="期間" :items="getTerms(3)" @change="onTermChanged" :full-width="false" />
      </v-sheet>
    </v-app-bar>

    <XLoader :loading="initializing">
      <template v-slot="{ loaded }">
        <div v-if="loaded">
          <v-tabs v-model="tab">
            <v-tab> 集計</v-tab>

            <v-tab v-for="month of 12" :key="`month${month}`"> {{ getMonth(dateStart, month) }}月 </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-card outlined>
                <v-card-text>
                  <span class="text-h6">合計</span>

                  <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                        <tr class="blue lighten-4">
                          <th scope="row">合計</th>

                          <th v-for="month of 12" :key="`monthHeader${month}`" class="table-secondary">
                            {{ getMonth(dateStart, month) }}月
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th>{{ getMerginSummery(null, null, null).amount | priceJP }}</th>

                          <th v-for="month of 12" :key="`monthHeader${month}`">
                            {{ getMerginSummery(getMonth(dateStart, month), null, null).amount | priceJP }}
                          </th>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>

                  <v-divider />

                  <v-row>
                    <v-col v-for="month of 12" :key="`monthHeader${month}`" cols="6">
                      <span class="text-h6">{{ getMonth(dateStart, month) }}月</span>
                      <v-simple-table dense>
                        <thead>
                          <tr class="blue lighten-4">
                            <th>項目</th>
                            <th>売上金額</th>
                            <th>調整額</th>
                            <th>報酬金額</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>契約（新規）</td>
                            <td>
                              {{
                                getMerginSummery(getMonth(dateStart, month), DemoMerginTypes.Demo, null, ContractTypes.IsNewContract)
                                  .merginAmount | priceJP
                              }}
                            </td>
                            <td>
                              {{
                                getMerginSummery(getMonth(dateStart, month), DemoMerginTypes.Demo, null, ContractTypes.IsNewContract)
                                  .adjustment | priceJP
                              }}
                            </td>
                            <td>
                              {{
                                getMerginSummery(getMonth(dateStart, month), DemoMerginTypes.Demo, null, ContractTypes.IsNewContract).amount
                                  | priceJP
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td>契約（リプレス）</td>
                            <td>
                              {{
                                getMerginSummery(getMonth(dateStart, month), DemoMerginTypes.Demo, null, ContractTypes.IsReplaceContract)
                                  .merginAmount | priceJP
                              }}
                            </td>
                            <td>
                              {{
                                getMerginSummery(getMonth(dateStart, month), DemoMerginTypes.Demo, null, ContractTypes.IsReplaceContract)
                                  .adjustment | priceJP
                              }}
                            </td>
                            <td>
                              {{
                                getMerginSummery(getMonth(dateStart, month), DemoMerginTypes.Demo, null, ContractTypes.IsReplaceContract)
                                  .amount | priceJP
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td>アポ獲得（新規）</td>

                            <td>
                              {{
                                getMerginSummery(getMonth(dateStart, month), DemoMerginTypes.Apo, null, ContractTypes.IsNewContract)
                                  .merginAmount | priceJP
                              }}
                            </td>
                            <td>
                              {{
                                getMerginSummery(getMonth(dateStart, month), DemoMerginTypes.Apo, null, ContractTypes.IsNewContract)
                                  .adjustment | priceJP
                              }}
                            </td>
                            <td>
                              {{
                                getMerginSummery(getMonth(dateStart, month), DemoMerginTypes.Apo, null, ContractTypes.IsNewContract).amount
                                  | priceJP
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td>すけさん</td>
                            <td>-</td>
                            <td>-</td>
                            <td>
                              {{ getMerginSummery(getMonth(dateStart, month), null, MerginTypes.Sukesan, null).amount | priceJP }}
                            </td>
                          </tr>

                          <tr>
                            <td>レンタル</td>
                            <td>-</td>
                            <td>-</td>
                            <td>
                              {{ getMerginSummery(getMonth(dateStart, month), null, MerginTypes.Rental, null).amount | priceJP }}
                            </td>
                          </tr>
                          <tr>
                            <td>やどーだ！</td>
                            <td>-</td>
                            <td>-</td>
                            <td>
                              {{ getMerginSummery(getMonth(dateStart, month), null, MerginTypes.Yadoda, null).amount | priceJP }}
                            </td>
                          </tr>
                          <tr>
                            <td>スマホ</td>
                            <td>-</td>
                            <td>-</td>
                            <td>
                              {{ getMerginSummery(getMonth(dateStart, month), null, MerginTypes.SP, null).amount | priceJP }}
                            </td>
                          </tr>
                          <tr>
                            <td>解約金相違分</td>
                            <td>-</td>
                            <td>-</td>
                            <td>
                              {{ getMerginSummery(getMonth(dateStart, month), null, MerginTypes.CancelPenalty, null).amount | priceJP }}
                            </td>
                          </tr>
                          <tr class="bottom-top">
                            <th>合計</th>
                            <th>
                              {{ getMerginSummery(getMonth(dateStart, month), null, null, null).merginAmount | priceJP }}
                            </th>
                            <th>
                              {{ getMerginSummery(getMonth(dateStart, month), null, null, null).adjustment | priceJP }}
                            </th>
                            <th>
                              {{ getMerginSummery(getMonth(dateStart, month), null, null, null).amount | priceJP }}
                            </th>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item v-for="month of 12" :key="`month${month}`">
              <v-card outlined>
                <v-card-text>
                  <v-simple-table class="mb-4" dense>
                    <thead>
                      <tr class="blue lighten-4">
                        <th></th>
                        <th>受注日</th>
                        <th>物件</th>
                        <th>売上金額</th>
                        <th>報酬金額</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colspan="5">
                          <v-chip :color="getDemoMerginTypeColor(DemoMerginTypes.Demo)" small>契約獲得</v-chip>
                        </td>
                      </tr>

                      <tr>
                        <td colspan="5">新規リース</td>
                      </tr>
                      <tr
                        v-for="(data, index) of getMerginDemos(
                          getMonth(dateStart, month),
                          DemoMerginTypes.Demo,
                          ContractTypes.IsNewContract
                        )"
                        :key="`demo_new_${index}`"
                      >
                        <th></th>
                        <th>{{ data.demo.saleDate | date }}</th>
                        <td>{{ data.demo.facility.name }}</td>
                        <td>{{ data.demo.merginAmount | priceJP }}</td>
                        <td>{{ data.amount | priceJP }}</td>
                      </tr>
                      <tr>
                        <td colspan="5">リプレイス</td>
                      </tr>

                      <tr
                        v-for="(data, index) of getMerginDemos(
                          getMonth(dateStart, month),
                          DemoMerginTypes.Demo,
                          ContractTypes.IsReplaceContract
                        )"
                        :key="`demo_replace_${index}`"
                      >
                        <th></th>
                        <th>{{ data.demo.saleDate | date }}</th>
                        <td>{{ data.demo.facility.name }}</td>
                        <td>{{ data.demo.merginAmount | priceJP }}</td>
                        <td>{{ data.amount | priceJP }}</td>
                      </tr>

                      <tr class="bottom-border">
                        <th>小計</th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>
                          {{
                            getMerginSummery(getMonth(dateStart, month), DemoMerginTypes.Demo, null, ContractTypes.Both).amount | priceJP
                          }}
                        </th>
                      </tr>

                      <tr>
                        <td colspan="5">
                          <v-chip :color="getDemoMerginTypeColor(DemoMerginTypes.Apo)" small>アポイント</v-chip>
                        </td>
                      </tr>

                      <tr>
                        <td colspan="5">新規リース</td>
                      </tr>
                      <tr
                        v-for="(data, index) of getMerginDemos(
                          getMonth(dateStart, month),
                          DemoMerginTypes.Apo,
                          ContractTypes.IsNewContract
                        )"
                        :key="`apo_new_${index}`"
                      >
                        <th></th>
                        <th>{{ data.demo.saleDate | date }}</th>
                        <td>{{ data.demo.facility.name }}</td>
                        <td>{{ data.demo.merginAmount | priceJP }}</td>
                        <td>{{ data.amount | priceJP }}</td>
                      </tr>
                      <tr>
                        <td colspan="5">リプレイス</td>
                      </tr>

                      <tr
                        v-for="(data, index) of getMerginDemos(
                          getMonth(dateStart, month),
                          DemoMerginTypes.Apo,
                          ContractTypes.IsReplaceContract
                        )"
                        :key="`apo_replace_${index}`"
                      >
                        <th></th>
                        <th>{{ data.demo.saleDate | date }}</th>
                        <td>{{ data.demo.facility.name }}</td>
                        <td>{{ data.demo.merginAmount | priceJP }}</td>
                        <td>{{ data.amount | priceJP }}</td>
                      </tr>

                      <tr class="bottom-border">
                        <th>小計</th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>
                          {{ getMerginSummery(getMonth(dateStart, month), DemoMerginTypes.Apo, null, ContractTypes.Both).amount | priceJP }}
                        </th>
                      </tr>
                      <tr class="bottom-border">
                        <th>計</th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>
                          {{ getMerginSummery(getMonth(dateStart, month), null, null, ContractTypes.Both).amount | priceJP }}
                        </th>
                      </tr>

                      <tr>
                        <td colspan="5">
                          <v-chip :color="getMerginTypeColor(MerginTypes.Sukesan)" small>すけさん</v-chip>
                        </td>
                      </tr>
                      <tr v-for="(data, index) of getMergins(getMonth(dateStart, month), MerginTypes.Sukesan)" :key="`sukesan_${index}`">
                        <th></th>
                        <th>{{ data.date | date }}</th>
                        <td>{{ data.facility.name }}</td>
                        <td>-</td>
                        <td>{{ data.amount | priceJP }}</td>
                      </tr>

                      <tr class="bottom-border">
                        <th>小計</th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>
                          {{ getMerginSummery(getMonth(dateStart, month), null, MerginTypes.Sukesan, null).amount | priceJP }}
                        </th>
                      </tr>

                      <tr>
                        <td colspan="5"><v-chip :color="getMerginTypeColor(MerginTypes.Rental)" small>レンタル</v-chip></td>
                      </tr>
                      <tr v-for="(data, index) of getMergins(getMonth(dateStart, month), MerginTypes.Rental)" :key="`rental_${index}`">
                        <th></th>
                        <th>{{ data.date | date }}</th>
                        <td>{{ data.facility.name }}</td>
                        <td>-</td>
                        <td>{{ data.amount | priceJP }}</td>
                      </tr>

                      <tr class="bottom-border">
                        <th>小計</th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>
                          {{ getMerginSummery(getMonth(dateStart, month), null, MerginTypes.Rental, null).amount | priceJP }}
                        </th>
                      </tr>

                      <tr>
                        <td colspan="5"><v-chip :color="getMerginTypeColor(MerginTypes.Yadoda)" small>やどーだ！</v-chip></td>
                      </tr>
                      <tr v-for="(data, index) of getMergins(getMonth(dateStart, month), MerginTypes.Yadoda)" :key="`yadoda_${index}`">
                        <th></th>
                        <th>{{ data.date | date }}</th>
                        <td>{{ data.facility.name }}</td>
                        <td>-</td>
                        <td>{{ data.amount | priceJP }}</td>
                      </tr>

                      <tr class="bottom-border">
                        <th>小計</th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>
                          {{ getMerginSummery(getMonth(dateStart, month), null, MerginTypes.Yadoda, null).amount | priceJP }}
                        </th>
                      </tr>

                      <tr>
                        <td colspan="5"><v-chip :color="getMerginTypeColor(MerginTypes.SP)" small>スマホ</v-chip></td>
                      </tr>
                      <tr v-for="(data, index) of getMergins(getMonth(dateStart, month), MerginTypes.SP)" :key="`sp_${index}`">
                        <th></th>
                        <th>{{ data.date | date }}</th>
                        <td>{{ data.facility.name }}</td>
                        <td>-</td>
                        <td>{{ data.amount | priceJP }}</td>
                      </tr>

                      <tr class="bottom-border">
                        <th>小計</th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>
                          {{ getMerginSummery(getMonth(dateStart, month), null, MerginTypes.SP, null).amount | priceJP }}
                        </th>
                      </tr>

                      <tr class="bottom-border">
                        <th>総計</th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>
                          {{ getMerginSummery(getMonth(dateStart, month), null, null, null).amount | priceJP }}
                        </th>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </div>
      </template>
    </XLoader>
  </div>
</template>


<script>
import { DemoMerginTypes, MerginTypes, GetDemoMerginTypeColor, GetMerginTypeColor } from '@/views/managements/mergins/Mergin';

export const ContractTypes = {
  IsNewContract: 1,
  IsReplaceContract: 1 << 1,
  Both: 1 << 3,
};

export default {
  components: {},

  filters: {
    boolLabel(value) {
      return value ? '〇' : '';
    },
  },
  computed: {
    DemoMerginTypes() {
      return DemoMerginTypes;
    },
    MerginTypes() {
      return MerginTypes;
    },
    ContractTypes() {
      return ContractTypes;
    },

    datas() {
      let datas = this.merginDatas;

      //
      return datas;
    },

    term: {
      get() {
        if (this.date == null) return null;
        return this.$options.filters.libertyTerm(this.date);
      },
      set(newValue) {
        this.date = this.$options.filters.libertyTermToFirstDate(newValue);
      },
    },

    // 開始日(期首)
    dateStart() {
      // 期首日を取得
      return this.$options.filters.getTermFirstDate(this.date);
    },

    // 終了日(期末の5月の最終日)
    dateEnd() {
      // 期首日を取得
      let moment = this.$moment(this.$options.filters.getTermFirstDate(this.date));
      let year = moment.year();
      return new Date(year + 1, 6 - 1, 0);
    },
  },

  data: () => ({
    initializing: false,

    date: null,

    tab: 0,

    dispSetting: {
      prospectAmount: false,
      resultAmount: false,
      resultRate: false,
    },

    pointEditor: {
      show: false,
      demo: null,
      point: null,
      pointMessage: null,
    },

    merginDatas: [],
    employees: [],
    rankingDatas: [],
  }),

  methods: {
    async onLoaded() {
      this.initializing = true;

      // 初期表示時は当期首月を指定する
      if (this.date == null) {
        this.date = this.$moment().add(-1, 'months').toDate();
      }

      // 指定期間の営業ポイント情報取得
      {
        let dateStart = this.$moment(this.dateStart).format();
        let dateEnd = this.$moment(this.dateEnd).format();

        let success = await this.get('analyse/datas/mergin', {
          dateStart: dateStart,
          dateEnd: dateEnd,
          employeeCode: this.$store.getters.user.code,
          confirmType: 1 << 1, // FIXME ハードコーディング中 業務承認済み
        });
        let data = success.data;
        this.merginDatas = data;

        // 初期タブは指定月
        let month = this.$moment(this.date).month() + 1;
        let tab = month + 6;
        if (month >= 6 && month <= 12) tab = month - 6;
        // 「全期間」のタブ分があるので+1
        this.tab = tab + 1;

        // 初期化終了
        this.initializing = false;
      }
    },

    onTermChanged() {
      // リロードする
      this.onLoaded();
    },

    // getMerginDetail(month, demoMerginType, merginType) {
    getMerginDemos(month, demoMerginType, contractTypes) {
      let datas = this.datas;

      //
      if (month != null) {
        datas = datas.filter((a) => a.month == month);
      } //

      // データフィルタ
      let demos = datas.filter((a) => a.demo != null).map((a) => a.demo);

      switch (contractTypes) {
        // 売上金額:新規
        case ContractTypes.IsNewContract: {
          demos = demos.filter((a) => a.isNewContract);
          break;
        }
        // リプレイス
        case ContractTypes.IsReplaceContract: {
          demos = demos.filter((a) => a.isReplaceContract);
          break;
        }
      }
      //
      let merginDemos = demos.map((a) => {
        let datas = a.merginDemos;
        if (demoMerginType != null) {
          datas = datas.filter((b) => b.demoMerginType == demoMerginType);
        }

        let amount = datas.reduce((sum, a) => (sum += a.mergin.amount), 0);
        return {
          demo: a,
          amount: amount,
        };
      });

      return merginDemos;
    },

    getMergins(month, merginType) {
      let datas = this.datas;

      if (month != null) {
        datas = datas.filter((a) => a.month == month);
      } //

      //
      let mergins = datas.filter((a) => a.mergin != null).map((a) => a.mergin);

      if (merginType != null) {
        mergins = mergins.filter((b) => b.merginType == merginType);
      }

      return mergins;
    },

    getMerginSummery(month, demoMerginType, merginType, contractTypes) {
      let datas = this.datas;

      //
      if (month != null) {
        datas = datas.filter((a) => a.month == month);
      } //

      if (merginType != null) {
        datas = datas.filter((a) => a.mergin != null && a.mergin.merginType == merginType);
      }
      // データフィルタ
      let demos = datas.filter((a) => a.demo != null).map((a) => a.demo);
      switch (contractTypes) {
        // 売上金額:新規
        case ContractTypes.IsNewContract: {
          demos = demos.filter((a) => a.isNewContract);
          break;
        }
        // リプレイス
        case ContractTypes.IsReplaceContract: {
          demos = demos.filter((a) => a.isReplaceContract);
          break;
        }
      }

      //
      let merginDemos = demos.flatMap((a) => a.merginDemos);
      let mergins = datas.filter((a) => a.mergin != null).map((a) => a.mergin);

      //
      if (demoMerginType != null) {
        merginDemos = merginDemos.filter((b) => b.demoMerginType == demoMerginType);
      }

      // 売上金額
      let merginAmount = 0;
      merginAmount += demos.reduce((sum, a) => (sum += a.merginAmount), 0);

      // 調整額
      let adjustment = 0;
      adjustment += merginDemos.reduce((sum, a) => (sum += a.mergin.adjustment), 0);

      // 報酬金額
      let amount = 0;
      amount += merginDemos.reduce((sum, a) => (sum += a.mergin.amount), 0);

      //
      if (contractTypes == null) {
        adjustment += mergins.reduce((sum, a) => (sum += a.adjustment), 0);
        amount += mergins.reduce((sum, a) => (sum += a.amount), 0);
      }

      // ここでemptyの構造に集計する
      return {
        merginAmount: merginAmount,
        adjustment: adjustment,
        amount: amount,
      };
    },

    getMonth(date, month) {
      return this.$moment(date)
        .add(month - 1, 'months')
        .format('M');
    },

    getDemoMerginTypeColor(type) {
      return GetDemoMerginTypeColor(type);
    },

    getMerginTypeColor(type) {
      return GetMerginTypeColor(type);
    },
  },
  watch: {},
  async mounted() {
    await this.onLoaded();
  },
};
</script>